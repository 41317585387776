import * as React from 'react'
import { styled } from '@mui/system'
import { Typography, Box, Stack } from '@mui/material'
import { Link } from 'gatsby'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import Content from 'components/content'
import { lightGrayBackground } from 'shared/styles'
import Logo from 'images/large-logo.svg'
import Twitter from 'images/twitter.svg'
import Insta from 'images/insta.svg'
import FB from 'images/facebook.svg'
import { brandPurple } from 'shared/styles'
import { StaticImage } from 'gatsby-plugin-image'

const StyledFooter = styled('footer')({
  backgroundColor: lightGrayBackground,
})
const StyledContent = styled(Content)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',

  '&': {
    paddingTop: '64px',
    paddingBottom: '64px',
  },

  [theme.breakpoints.down('lg')]: {
    padding: '48px 32px',
  },
  [theme.breakpoints.down(722)]: {
    flexDirection: 'column',
  },
}))
const AddressContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 0%',
  flexDirection: 'column',

  [theme.breakpoints.down(722)]: {
    padding: '48px 0',
  },
}))
const LinksContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  fontWeight: 700,
  marginBottom: '24px',
  color: brandPurple,

  [theme.breakpoints.down(1052)]: {
    flexDirection: 'column',
  },
}))
const StyledLink = styled(Link)({
  textDecoration: 'underline',
  fontWeight: 700,
  color: brandPurple,

  '&:visited': {
    color: brandPurple,
  },
})
const StyledA = styled('a')({
  textDecoration: 'underline',
  fontWeight: 700,
  color: brandPurple,

  '&:visited': {
    color: brandPurple,
  },
})
const LinkSpacer = styled('div')(({ theme }) => ({
  flex: '0 0 25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down(1052)]: {
    display: 'none',
  },
}))
const IconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: 'center',
})
const SocialLink = styled('a')({
  marginRight: '32px',
})

const Footer = () => (
  <StyledFooter>
    <StyledContent>
      <img src={Logo} alt="logo" />
      <AddressContainer pl={8}>
        <LinksContainer justifyContent="center" pr={5}>
          <Typography>
            <StyledLink
              to="/terms"
              data-track-gtm="Marketing Site|Footer|Terms of Service"
            >
              Terms of Service
            </StyledLink>
          </Typography>
          <LinkSpacer>|</LinkSpacer>
          <Typography>
            <StyledLink
              to="/privacy"
              data-track-gtm="Marketing Site|Footer|Privacy Policy"
            >
              Privacy Policy
            </StyledLink>
          </Typography>
          <LinkSpacer>|</LinkSpacer>
          <Typography>
            <StyledLink
              to="/cookies"
              data-track-gtm="Marketing Site|Footer|Cookie Notice"
            >
              Cookie Notice
            </StyledLink>
          </Typography>
          <LinkSpacer>|</LinkSpacer>
          <Typography>
            <StyledA href="/blog" data-track-gtm="Marketing Site|Footer|Blog">
              Blog
            </StyledA>
          </Typography>
          {/*<LinkSpacer>|</LinkSpacer>*/}
          {/*<Typography>*/}
          {/*  <StyledLink to="/faq" data-track-gtm="Marketing Site|Footer|FAQ">*/}
          {/*    FAQ*/}
          {/*  </StyledLink>*/}
          {/*</Typography>*/}
        </LinksContainer>
        <Typography fontWeight={600} fontSize="0.875rem">
          9200 Worthington Road, Suite 210
          <br />
          Westerville, Ohio 43082
        </Typography>
        <Typography fontWeight={600} fontSize="0.75rem" pt={1}>
          © SwayDM 2022. All rights reserved.
        </Typography>
      </AddressContainer>
      <IconsContainer>
        {/*<SocialLink*/}
        {/*  href="https://twitter.com/swaydmme"*/}
        {/*  target="_blank"*/}
        {/*  rel="noreferrer"*/}
        {/*  data-track-gtm="Marketing Site|Footer|Twitter"*/}
        {/*>*/}
        {/*  <img src={Twitter} alt="twitter" />*/}
        {/*</SocialLink>*/}
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <SocialLink
            href="https://www.instagram.com/swaydm/"
            target="_blank"
            rel="noreferrer"
            data-track-gtm="Marketing Site|Footer|Instagram"
          >
            <img src={Insta} alt="instagram" />
          </SocialLink>
          {/*<SocialLink*/}
          {/*  href="https://www.facebook.com/swaydmme/"*/}
          {/*  target="_blank"*/}
          {/*  rel="noreferrer"*/}
          {/*  data-track-gtm="Marketing Site|Footer|Facebook"*/}
          {/*>*/}
          {/*  <img src={FB} alt="facebook" />*/}
          {/*</SocialLink>*/}
          <a
            href="https://www.linkedin.com/company/swaydm/"
            target="_blank"
            rel="noreferrer"
            data-track-gtm="Marketing Site|Footer|LinkedIn"
          >
            <LinkedInIcon
              style={{ color: brandPurple }}
              // This size ends up with the actual svg path size matching the existing icons
              sx={{ height: '56px', width: '56px', ml: '-7px' }}
            />
          </a>
        </Stack>
        <Stack gap={4} sx={{ alignItems: 'center' }} direction="row" mr={1}>
          <a
            href="https://link.sway.dm/e/app-store/android"
            target="_blank"
            sx={{ marginRight: '100px' }}
          >
            <StaticImage
              src="../images/google-play-badge.png"
              alt="Get it on Google Play"
              width={175}
            />
          </a>
          <a href="https://link.sway.dm/e/app-store/ios" target="_blank">
            <StaticImage
              src="../images/app-store-badge.png"
              alt="Get it on Apple App Store"
              width={175}
            />
          </a>
        </Stack>
      </IconsContainer>
    </StyledContent>
  </StyledFooter>
)

export default Footer
